import manager_http from '@/js/http/manager.js';
let axios = manager_http().axios();

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchAll(ctx) {
    try {
      let {
        data: { data: users },
      } = await axios.get(`/users`);
      return users;
    } catch (err) {
      throw error.response.data;
    }
  },

  async fetchByID(ctx, id) {
    try {
      let {
        data: { data: user },
      } = await axios.get(`/users/${id}`);
      return user;
    } catch (error) {
      throw error.response.data;
    }
  },

  async add(ctx, data) {
    try {
      let {
        data: { success, message },
      } = await axios.post(`/users`, data);
      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateByID(ctx, { id, data }) {
    try {
      let {
        data: { success, message },
      } = await axios.put(`/users/${id}`, data);
      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteByID(ctx, id) {
    try {
      let response = await axios.delete(`/users/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
