<template>
  <b-tab :title="__('Billplz')">
    <Billplz />
  </b-tab>
</template>

<script>
import Billplz from './Settings';

export default {
  components: {
    Billplz,
  },
};
</script>
