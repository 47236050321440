import axios from 'axios';

const http = function (args) {
  return {
    getBaseUrl() {
      return `${window.location.protocol}//${FEES_HOST}`;
    },

    axios(axios_options) {
      let axios_instance = axios.create({
        baseURL: this.getBaseUrl(),
        timeout: 1000,
        ...axios_options,
      });

      this.configureInterceptor(axios_instance, this.successHandler, this.errorHandler);

      return axios_instance;
    },

    configureInterceptor(axios_instance, successHandler, errorHandler) {
      axios_instance.interceptors.response.use(successHandler, errorHandler);
    },

    successHandler: (response) => response,

    errorHandler: (error) => {
      return Promise.reject(error);
    },
  };
};

export default http;
