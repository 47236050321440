const state = {
  settings: [],
};

const getters = {
  setting: (name) => {
    return this.settings.filter((item) => (item.name = name));
  },
};

const mutations = {};

const actions = {
  fetchAll({ state }) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/api/settings')
        .then(({ data: { data: settings } }) => {
          state.settings = settings;
          resolve(settings);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
