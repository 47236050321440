<template>
  <b-tab :title="__('Toyyibpay')">
    <Toyyibpay />
  </b-tab>
</template>

<script>
import Toyyibpay from './Settings';

export default {
  components: {
    Toyyibpay,
  },
};
</script>
