const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchAll(ctx) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${window.location.protocol}//${FEES_HOST}/api/packages`)
        .then(({ data: { data: packages } }) => {
          resolve(packages);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchByID(ctx, id) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`/api/packages/${id}`)
        .then(({ data: { data: package_info } }) => {
          resolve(package_info);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  add(ctx, data) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post('/api/packages', data)
            .then(({ data: { success, message } }) => {
              resolve({ success, message });
            })
            .catch((error2) => {
              reject(error2.response.data);
            });
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateByID(ctx, { id, data }) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          let postData = { ...data, _method: 'put' };

          axios
            .post(`/api/packages/${id}`, postData)
            .then(
              ({
                data: {
                  success,
                  message,
                  data: { package: package_info },
                },
              }) => {
                resolve({ success, message, package: package_info });
              },
            )
            .catch((error2) => {
              reject(error2.response.data);
            });
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  async deleteByID(ctx, id) {
    try {
      let {
        data: { success, message },
      } = await axios.post(`/api/packages/${id}`, { _method: 'delete' });

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
