<template>
  <div>
    <breadcrumb :page="__('General Settings')" />

    <b-row>
      <b-col sm="12">
        <b-form @submit.prevent="saveSettings" v-if="!fetching">
          <b-form-group :label="__('Banned Subdomains')">
            <vue-tags-input
              v-model="subdomainTag"
              :tags="bannedSubdomains"
              class="tag-custom text-15"
              @tags-changed="(newTags) => (bannedSubdomains = newTags)"
              :placeholder="__('Add subdomain...')"
              @before-deleting-tag="handleDeleteSubdomain"
              @before-adding-tag="handleAddSubdomain"
            />
          </b-form-group>

          <b-form-group>
            <b-button type="submit" variant="primary" :disabled="processing">
              <b-spinner variant="light" small v-if="processing"></b-spinner>
              <span>{{ __('Save') }}</span>
            </b-button>
          </b-form-group>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      settingBannedSubdomains: {},
      bannedSubdomains: [],
      subdomainTag: '',
      processing: false,
    };
  },

  mounted() {
    this.fetchBannedSubdomains();
  },

  methods: {
    fetchBannedSubdomains() {
      this.fetching = true;
      axios.get('/api/settings/showbyname/banned_subdomains').then(({ data: { data: setting_banned_subdomains } }) => {
        this.fetching = false;
        this.$set(this, 'settingBannedSubdomains', setting_banned_subdomains);
        this.bannedSubdomains = setting_banned_subdomains.value.map((subdomain) => {
          return { text: subdomain };
        });
      });
    },

    handleAddSubdomain(obj) {
      this.processing = true;

      let postData = {
        _method: 'put',
        value: JSON.stringify(this.bannedSubdomains.map((item) => item.text).concat([obj.tag.text])),
      };

      let endpoint = `/api/settings/${this.settingBannedSubdomains.id}`;

      axios
        .post(endpoint, postData)
        .then((response) => {
          obj.addTag();
        })
        .catch((err) => {
          // WIP: handle error
        })
        .finally(() => {
          this.processing = false;
        });
    },

    handleDeleteSubdomain(obj) {
      let postData = {
        _method: 'put',
        value: JSON.stringify(
          this.bannedSubdomains.filter((item) => item.text != obj.tag.text).map((item) => item.text),
        ),
      };

      let endpoint = `/api/settings/${this.settingBannedSubdomains.id}`;

      axios
        .post(endpoint, postData)
        .then((response) => {
          obj.deleteTag();
        })
        .catch((err) => {
          // WIP: handle error
        });
    },

    saveSettings() {
      this.success = true;
      this.message = this.__('Saved successfully');
    },
  },
};
</script>

<style></style>
