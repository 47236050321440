<template>
  <div class="">
    <breadcrumb :page="__('Default Package Settings')" />

    <b-row>
      <b-col sm="12" md="4" lg="4">
        <b-form @submit.prevent="saveSettings" v-if="!fetching">
          <b-form-group :label="__('Package')">
            <v-select
              label="name"
              :options="packages"
              :value="selectedPackage"
              @input="handleChangePackage"
              :placeholder="__('Select package...')"
            >
            </v-select>
          </b-form-group>

          <b-form-group :label="__('Subpackage')">
            <v-select
              label="display_subpackage"
              :options="subpackages"
              :value="selectedSubpackage"
              @input="handleChangeSubpackage"
              :placeholder="__('Select subpackage...')"
            >
            </v-select>
          </b-form-group>

          <b-button variant="primary" @click="saveSettings" class="mb-2">
            {{ __('Save') }}
          </b-button>

          <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
            {{ message }}
          </b-alert>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from 'moment';
import axios from 'axios';

export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      packages: [],
      subpackages: [],
      selectedPackage: null,
      selectedSubpackage: null,
    };
  },

  async mounted() {
    this.packages = await this.fetchPackages();
    const setting = await this.fetchSetting();

    this.selectedPackage = this.packages.find((pkg) => {
      if (setting === undefined || setting.package === null) {
        return false;
      }
      return pkg.id === setting.package.id;
    });
    this.subpackages = await this.fetchSubpackagesFor(
      this.selectedPackage === undefined ? '1' : this.selectedPackage.id,
    );
    this.selectedSubpackage = this.subpackages.find(
      (subpkg) => subpkg.id === (setting === undefined ? null : setting.id),
    );
  },

  methods: {
    async fetchPackages() {
      const {
        data: { data: packages },
      } = await axios.get('/api/packages');
      return packages;
    },

    async fetchSubpackagesFor(package_id) {
      let {
        data: { data: subpackages },
      } = await axios.get(`/api/packages/${package_id}/subpackages`);
      return subpackages.map((subpackage, index) => ({
        ...subpackage,
        display_subpackage: `${__('Option')} ${index + 1} - ${__(moment.duration(subpackage.period).humanize())} (RM ${
          subpackage.price
        })`,
      }));
    },

    async fetchSetting() {
      this.fetching = true;

      try {
        const {
          data: { data: setting },
        } = await axios.get('/api/fallback_subpackage');

        return setting;
      } catch (err) {
      } finally {
        this.fetching = false;
      }
    },

    async saveSettings() {
      let postData;

      if (!this.selectedPackage && !this.selectedSubpackage) {
        postData = {
          subpackage_id: null,
        };
      } else if (this.selectedPackage && !this.selectedSubpackage) {
        this.success = false;
        this.message = __('Subpackage must be selected');
        return;
      } else {
        postData = {
          subpackage_id: this.selectedSubpackage.id,
        };
      }

      try {
        let response = await axios.post('/api/fallback_subpackage', postData);
        this.success = response.data.success;
        this.message = response.data.message;
      } catch (err) {
        console.log(err);
        this.success = false;
        this.message =
          err.response.data.message && err.response.data.errors.subpackage_id
            ? err.response.data.errors.subpackage_id[0]
            : __('Something went wrong');
      }
    },

    handleChangePackage(selection) {
      if (selection == null) {
        this.selectedPackage = null;
        this.selectedSubpackage = null;
        this.subpackages = [];
        return;
      }

      this.selectedPackage = selection;
      this.fetchSubpackagesFor(selection.id).then((subpackages) => {
        this.selectedSubpackage = null;
        this.subpackages = subpackages;
      });
    },

    handleChangeSubpackage(selection) {
      this.selectedSubpackage = selection;
    },
  },
};
</script>

<style></style>
