<template>
  <div>If you can see this page it means there is something wrong. Please check the configuration file.</div>
</template>

<script>
export default {
  metaInfo: {
    title: 'Oops!',
  },
};
</script>

<style>
/* .spinner.sm {
  height: 2em;
  width: 2em;
}

.app-footer ul {
  list-style-type: none;
} */
</style>
