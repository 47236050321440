<template>
  <b-tab :title="__('General')">
    <router-link :to="{ name: 'settings.site' }" custom v-slot="{ navigate }">
      <General />
    </router-link>
  </b-tab>
</template>

<script>
import General from './Site';

export default {
  components: {
    General,
  },
};
</script>
