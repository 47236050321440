const state = {};

const getters = {
  getBillplzSandboxMode() {
    return true;
  },
};

const mutations = {};

const actions = {};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
