<template>
  <b-tab :title="__('Default Package')">
    <FallbackPackage />
  </b-tab>
</template>

<script>
import FallbackPackage from './Settings';

export default {
  components: {
    FallbackPackage,
  },
};
</script>
