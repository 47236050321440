import Vuex from 'vuex';
import Vue from 'vue';
import largeSidebar from './modules/largeSidebar';
import config from './modules/config';
import auth from './modules/auth';
import packages from './modules/packages';
import users from './modules/users';
import modules_store from '@/js/modules/store.js';
import subpackages from './modules/subpackages';
import categories from './modules/categories';
import customers from './modules/customers';
import subscriptions from './modules/subscriptions';
import settings from './modules/settings';
import managers from './modules/managers';
import manager_dashboard from './modules/manager_dashboard';
import subcustomers from './modules/subcustomers';
import customer_subcustomers from './modules/customer_subcustomers';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    largeSidebar,
    config,
    auth,
    packages,
    users,
    ...modules_store,
    subpackages,
    categories,
    customers,
    subscriptions,
    settings,
    managers,
    manager_dashboard,
    subcustomers,
    customer_subcustomers,
  },
});
