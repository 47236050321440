<template>
  <div>
    <breadcrumb :page="__('PayPal Settings')" />

    <b-row>
      <b-col sm="12">
        <b-form @submit.prevent="saveSettings" v-if="!fetching">
          <b-form-group :label="__('Activate')">
            <b-form-checkbox v-model="setting.enabled"></b-form-checkbox>
          </b-form-group>

          <b-form-group :label="__('Mode')">
            <b-select
              :options="[
                { text: 'Sandbox', value: 'sandbox' },
                { text: 'Live', value: 'live' },
              ]"
              v-model="setting.settings.mode"
              :placeholder="__('Select Mode...')"
            >
            </b-select>
          </b-form-group>

          <b-form-group>
            <h4>{{ __('Test Mode Settings') }}</h4>

            <b-form-group :label="__('App ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.sandbox.app_id"
                :placeholder="__('Enter app ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('Client ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.sandbox.client_id"
                :placeholder="__('Enter client ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('Client Secret')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.sandbox.client_secret"
                :placeholder="__('Enter client secret key...')"
              ></b-form-input>
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <h4>{{ __('Live Mode Settings') }}</h4>

            <b-form-group :label="__('App ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.live.app_id"
                :placeholder="__('Enter app ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('Client ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.live.client_id"
                :placeholder="__('Enter client ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('Client Secret')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.live.client_secret"
                :placeholder="__('Enter client secret key...')"
              ></b-form-input>
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <b-button type="submit" variant="primary">
              <b-spinner variant="light" small v-if="false"></b-spinner>
              <span>{{ __('Save') }}</span>
            </b-button>
          </b-form-group>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      setting: {
        settings: {},
      },
    };
  },

  beforeMount() {
    this.fetchSetting();
  },

  methods: {
    fetchSetting() {
      this.fetching = true;
      axios
        .get('/api/payment_gateways/showByName/paypal')
        .then(({ data: { data: setting } }) => {
          console.log(setting);
          this.$set(this, 'setting', { ...setting, enabled: setting.enabled == 1 });
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    },

    saveSettings() {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          let postData = {
            _method: 'put',
            enabled: this.setting.enabled == 1,
            ...this.setting,
          };

          axios
            .post(`/api/payment_gateways/${this.setting.id}`, postData)
            .then(({ data: { message, success } }) => {
              this.message = message;
              this.success = success;
            })
            .catch((error) => {
              console.log(error);
              alert(error);
            })
            .finally(() => {});
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        })
        .finally(() => {});
    },
  },
};
</script>

<style></style>
