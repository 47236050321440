import manager_http from '@/js/http/manager.js';

const SET_NAME = 'setName';
const SET_EMAIL = 'setEmail';
const SET_EMAIL_VERIFIED = 'setEmailVerified';
const SET_PASSWORD = 'setPassword';
const SET_PASSWORD_CONFIRMATION = 'setPasswordConfirmation';
const SET_MANAGER = 'setManager';

let axios = manager_http().axios();

const state = {
  name: '',
  email: '',
  email_is_verified: '',
  password: '',
  password_confirmation: '',
};

const getters = {
  name: (state) => state.name,

  email: (state) => state.email,

  password: (state) => state.password,

  password_confirmation: (state) => state.password_confirmation,

  manager: (state) => {
    return {
      name: state.name,
      email: state.email,
      password: state.password,
      password_confirmation: state.password_confirmation,
    };
  },
};

const mutations = {
  [SET_NAME](state, { name }) {
    state.name = name;
  },
  [SET_EMAIL](state, { email }) {
    state.email = email;
  },
  [SET_EMAIL_VERIFIED](state, { email_is_verified }) {
    state.email_is_verified = email_is_verified;
  },
  [SET_PASSWORD](state, { password }) {
    state.password = password;
  },
  [SET_PASSWORD_CONFIRMATION](state, { password_confirmation }) {
    state.password_confirmation = password_confirmation;
  },
  [SET_MANAGER](state, { manager }) {
    state.name = manager.name;
    state.email = manager.email;
    state.email_is_verified = manager.email_is_verified;
    state.password = manager.password;
    state.password_confirmation = manager.password_confirmation;
  },
};

const actions = {
  async fetchAll(ctx) {
    try {
      let {
        data: { data: managers },
      } = await axios.get('/managers');
      return managers;
    } catch (error) {
      throw error.response.data;
    }
  },

  async add({ state, commit, rootState }, manager) {
    try {
      let {
        data: { success, message },
      } = await axios.post('/managers', manager);

      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async fetchByID(ctx, id) {
    try {
      let {
        data: { data: manager },
      } = await axios.get(`/managers/${id}`);
      return manager;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateByID(ctx, { id, data }) {
    try {
      let {
        data: {
          success,
          message,
          data: { manager },
        },
      } = await axios.put(`/managers/${id}`, data);

      return { success, message, manager };
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteByID(ctx, id) {
    try {
      let response = await axios.delete(`/managers/${id}`);
      return response;
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
