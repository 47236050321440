const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchAll(ctx) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/api/products')
        .then(({ data: { data: products } }) => {
          resolve(products);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  add(ctx, data) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post('/api/products', data)
            .then(({ data: { message, success } }) => {
              resolve({ message, success });
            })
            .catch((error) => {
              reject(error.response.data);
            });
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchByID(ctx, id) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`/api/products/${id}`)
        .then(({ data: { data: product } }) => {
          resolve(product);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  updateByID(ctx, { id, data }) {
    return new Promise(function (resolve, reject) {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post(`/api/products/${id}`, { ...data, _method: 'put' })
            .then(({ data: { message, success } }) => {
              resolve({ message, success });
            })
            .catch((error2) => {
              reject(error2.response.data);
            });
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  deleteByID(ctx, id) {
    return new Promise(function (resolve, reject) {});
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
