<template>
  <b-tab :title="__('Manual Payment')">
    <ManualPayment />
  </b-tab>
</template>

<script>
import ManualPayment from './Settings';

export default {
  components: {
    ManualPayment,
  },
};
</script>
