<template>
  <b-tab :title="__('Business')">
    <Business />
  </b-tab>
</template>

<script>
import Business from './Settings';

export default {
  components: {
    Business,
  },
};
</script>
