import App from './views/App.vue';
import Breadcrumb from './components/breadcrumb';
import GullKit from './plugins/gull.kit';
import i18n from './lang/lang';
import router from './router';
import store from './store';
import Vue from 'vue';

import {
  formatDate,
  getCurrentYear,
  humanReadablePeriod,
  roundPrice,
  formatBytes,
  showSuccessNotification,
  showErrorNotification,
  isExpiredSubscription,
  titleCase,
  truncateString,
} from './helper';

require('./bootstrap');

// Language Translator
import Lang from './../../vendor/apih/laravel-jslang/resources/js/lang';
window.Lang = Lang;
window.__ = Lang.get;
window.trans = Lang.get;
window.trans_choice = Lang.choice;
Vue.prototype.__ = Lang.get;
Vue.prototype.trans = Lang.get;
Vue.prototype.trans_choice = Lang.trans_choice;

Vue.prototype.formatDate = formatDate;
Vue.prototype.getCurrentYear = getCurrentYear;
Vue.prototype.humanReadablePeriod = humanReadablePeriod;
Vue.prototype.roundPrice = roundPrice;
Vue.prototype.formatBytes = formatBytes;
Vue.prototype.showSuccessNotification = showSuccessNotification;
Vue.prototype.showErrorNotification = showErrorNotification;
Vue.prototype.isExpiredSubscription = isExpiredSubscription;
Vue.prototype.titleCase = titleCase;
Vue.prototype.truncateString = truncateString;

Vue.use(GullKit);

Vue.component('breadcrumb', Breadcrumb);

Vue.config.productionTip = false;

const DEFAULT_LANG = 'ms';

const app = new Vue({
  el: '#app',
  components: {
    App,
  },
  router,
  Lang,
  store,
  i18n,
});

let storeLocale = app.$store.getters.getLocale;

let locale = storeLocale ? storeLocale : DEFAULT_LANG;

app.$i18n.locale = locale;
