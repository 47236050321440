const state = {};

const getters = {};

const mutations = {};

const actions = {
  fetchAllForPackage(ctx, package_id) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${window.location.protocol}//${FEES_HOST}/api/packages/${package_id}/subpackages`)
        .then(({ data: { data: subpackages } }) => {
          resolve(subpackages);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },

  fetchByID(ctx, subpackage_id) {
    return new Promise(function (resolve, reject) {
      axios
        .get(`${window.location.protocol}//${FEES_HOST}/api/subpackages/${subpackage_id}`)
        .then(({ data: { data: subpackage } }) => {
          resolve(subpackage);
        })
        .catch((error) => {
          reject(error.response.data);
        });
    });
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
