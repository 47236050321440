<template>
  <div>
    <breadcrumb :page="__('Affiliate Settings')" />

    <b-row>
      <b-col sm="12" md="4" lg="4">
        <b-form @submit.prevent="saveSettings" v-if="!fetching">
          <b-form-group :label="__('Commission Type')">
            <v-select
              :options="possibleCommissionTypes"
              v-model="setting.commission_type"
              :placeholder="__('Select commission type...')"
            >
            </v-select>
          </b-form-group>

          <b-form-group :label="__('Commission Amount')">
            <b-form-input
              class="form-control"
              v-model="setting.commission_amount"
              :placeholder="__('Enter commission amount...')"
            ></b-form-input>
          </b-form-group>

          <b-form-group>
            <b-button type="submit" variant="primary">
              <span>{{ __('Save') }}</span>
            </b-button>
          </b-form-group>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
const possibleCommissionTypes = ['flat', 'percentage'];

import axios from 'axios';
export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      settingId: 0,
      setting: {
        commission_type: '',
        commission_amount: '',
      },
    };
  },

  computed: {
    possibleCommissionTypes() {
      return possibleCommissionTypes;
    },
  },

  async mounted() {
    await this.fetchSetting();
  },

  methods: {
    async fetchSetting() {
      this.fetching = true;

      try {
        let response = await axios.get('/api/settings/showbyname/affiliate');
        let settings = response.data.data;
        this.settingId = settings.id;
        this.setting = settings.value;
      } catch (err) {
      } finally {
        this.fetching = false;
      }
    },

    async saveSettings() {
      let postData = {
        value: JSON.stringify(this.setting),
      };

      try {
        let response = await axios.put(`/api/settings/${this.settingId}`, postData);
        this.success = response.data.success;
        this.message = response.data.message;
      } catch (err) {
        this.success = false;
        this.message = err.response.data.message;
      }
    },
  },
};
</script>

<style></style>
