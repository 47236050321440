export default {
  global: {
    field: {
      action: 'Action',
    },

    action: {
      edit: 'Edit',
    },

    status: {},
  },

  sidebar: {
    dashboard: 'Halaman Utama',
    packages: 'Pakej',
    managers: 'Pengurus',
    users: 'Pengguna',
    invoices: 'Invois',
    payments: 'Bayaran',
    settings: 'Tetapan',
  },

  dashboard: {
    page_title: 'Halaman Utama',
    totalUsers: 'Pengguna',
    totalSubscriptions: 'Langganan',
    todays_sales: 'Jualan',
    unpaid_invoices: 'Invois Belum Dibayar',
  },

  manager: {
    // sidebar_item: 'Manager',
    page_title: {
      index: 'Pengurus',
      create: 'Tambah Pengurus',
      edit: 'Sunting Pengurus',
      show: 'Lihat Pengurus',
    },
    action: {
      add: 'Tambah',
      edit: 'Ubah',
      save: 'Simpan',
      update: 'Kemas kini',
      delete: 'Padam',
    },
    fields: {
      name: 'Nama',
      form_name: 'Manager Name', //remove
      name_form: 'Manager Name',
      email: 'Emel',
      form_email: 'Manager Email', //remove
      email_form: 'Emel Pengurus',
      action: 'Tindakan',
      email_verified: 'Emel disahkan',
      password: 'Kata Laluan',
      password_form: 'Kata Laluan Pengurus',
      password_confirmation: 'Sahkan Kata Laluan',
      password_confirmation_form: 'Sahkan Kata Laluan Pengurus',
      registration_date: 'Tarikh Pendaftaran',
    },
    status: {
      email: {
        verified: 'Disahkan',
        unverified: 'Belum Disahkan',
      },
      phone: {
        verified: 'Disahkan',
        unverified: 'Belum Disahkan',
      },
    },
  },

  user: {
    // sidebar_item: 'User',
    page_title: {
      index: 'Pengguna',
      edit: 'Sunting Pengguna',
      create: 'Tambah Pengguna',
      show: 'Lihat Pengguna',
    },
    action: {
      add: 'Tambah',
      edit: 'Sunting',
      save: 'Simpan',
      update: 'Kemas kini',
      delete: 'Padam',
    },
    fields: {
      name: 'Nama',
      name_form: 'Nama Pengguna',
      email: 'Emel',
      email_form: 'Emel Pengguna',
      password: 'Kata Laluan',
      password_form: 'Kata Laluan Pengguna',
      subdomain: 'Subdomain',
      subdomain_form: 'Subdomain Pengguna',
      password_confirmation: 'Sahkan Kata Laluan',
      password_confirmation_form: 'Sahkan Kata Laluan Pengguna',
      registration_date: 'Tarikh Pendaftaran',

      email_verified: 'Emel Disahkan',
      action: 'Tindakan',
    },
    status: {
      email: {
        verified: 'Disahkan',
        unverified: 'Belum Disahkan',
      },
      phone: {
        verified: 'Disahkan',
        unverified: 'Belum Disahkan',
      },
    },
  },

  user_subscriptions: {
    action: {},

    labels: {
      package: 'Packej',
      actions: 'Tindakan',
    },

    fields: {
      id: 'ID',
      start_date: 'Tarikh Mula',
      end_date: 'Tarikh Akhir',
      renew_date: 'Tarikh Memperbaharui',
    },

    status: {},
  },

  invoice: {},

  my_invoice: {},

  customer_invoice: {},
};
