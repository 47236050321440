import Vue from 'vue';
import VueRouter from 'vue-router';

const Homepage = () => import('./views/app/index.vue');

const Dashboard = () => import('./views/Dashboard/Index.vue');
const Profile = () => import('./views/Profile/Index.vue');

const Logout = () => import('./views/Logout.vue');

const ManagerLogin = () => import('./views/Manager/Auth/Login');
const ManagerForgotPassword = () => import('./views/Manager/Auth/Forgot');
const ManagerPasswordReset = () => import('./views/Manager/Auth/PasswordReset');

const AffiliateLogin = () => import('./views/Affiliate/Auth/Login');
const AffiliateRegister = () => import('./views/Affiliate/Auth/Register');
const AffiliateForgotPassword = () => import('./views/Affiliate/Auth/Forgot');
const AffiliatePasswordReset = () => import('./views/Affiliate/Auth/PasswordReset');

// affiliate module - manager side
const ManagerAffiliateAgentsIndex = () => import('./views/Affiliate/ManagerAffiliateAgentsIndex');
const ManagerAffiliateAgentsShow = () => import('./views/Affiliate/ManagerAffiliateAgentsShow');
const ManagerAffiliateAgentsEdit = () => import('./views/Affiliate/ManagerAffiliateAgentsEdit');

const ManagerAffiliateCommissionsIndex = () => import('./views/Affiliate/ManagerAffiliateCommissionsIndex');
const ManagerAffiliateCommissionsEdit = () => import('./views/Affiliate/ManagerAffiliateCommissionsEdit');

const ManagerAffiliatePaymentsIndex = () => import('./views/Affiliate/ManagerAffiliatePaymentsIndex');
const ManagerAffiliatePaymentsEdit = () => import('./views/Affiliate/ManagerAffiliatePaymentsEdit');

// affiliate module - affiliate side
const AffiliateCommissionsIndex = () => import('./views/Affiliate/Commissions/Index.vue');
const AffiliateReferralsIndex = () => import('./views/Affiliate/Referrals/Index.vue');
const AffiliateWithdrawalsIndex = () => import('./views/Affiliate/Withdrawals/Index.vue');

const UserLogin = () => import('./views/User/Auth/Login');
const UserForgotPassword = () => import('./views/User/Auth/Forgot');
const UserRegister = () => import('./views/User/Auth/Register');
const UserPasswordReset = () => import('./views/User/Auth/PasswordReset');

const CustomerRegister = () => import('./views/Customer/Auth/Register.vue');
const CustomerLogin = () => import('./views/Customer/Auth/Login.vue');
const CustomerForgotPassword = () => import('./views/Customer/Auth/Forgot');
const CustomerPasswordReset = () => import('./views/Customer/Auth/PasswordReset');

const PackagesIndex = () => import('./views/Package/Index.vue');
const PackagesCreate = () => import('./views/Package/Create.vue');
const PackagesShow = () => import('./views/Package/Show.vue');
const PackagesEdit = () => import('./views/Package/Edit.vue');

const ManagerIndex = () => import('./views/Manager/Index.vue');
const ManagerShow = () => import('./views/Manager/Show.vue');
const ManagerCreate = () => import('./views/Manager/Create.vue');
const ManagerEdit = () => import('./views/Manager/Edit.vue');

const UsersIndex = () => import('./views/Users/Index.vue');
const UsersShow = () => import('./views/Users/Show.vue');
const UsersCreate = () => import('./views/Users/Create.vue');
const UsersEdit = () => import('./views/Users/Edit.vue');

const UserSubscriptionCreate = () => import('./views/Users/Subscriptions/Create.vue');
const UserSubscriptionEdit = () => import('./views/Users/Subscriptions/Edit.vue');

const CustomerSubscriptionIndex = () => import('./views/Customers/Subscriptions/CustomerSubscriptionIndex.vue');
const CustomerSubscriptionCreate = () => import('./views/Customers/Subscriptions/Create.vue');
const CustomerSubscriptionEdit = () => import('./views/Customers/Subscriptions/Edit.vue');

const InvoicesIndex = () => import('./views/Invoices/Index.vue');
const InvoicesShow = () => import('./views/Invoices/Show.vue');
const InvoicesCreate = () => import('./views/Invoices/Create.vue');
const InvoicesSettings = () => import('./views/Invoices/Settings.vue');
const InvoicesEdit = () => import('./views/Invoices/Edit.vue');
const UserInvoicesPay = () => import('./views/Invoices/UserInvoicesPay.vue');

const ManualInvoicePayment = () => import('./views/CustomerInvoices/ManualPayment.vue');

const PaymentsIndex = () => import('./views/Payments/Index.vue');
const PaymentsCreate = () => import('./views/Payments/Create.vue');
const PaymentsShow = () => import('./views/Payments/Show.vue');
const PaymentsEdit = () => import('./views/Payments/Edit.vue');
const PaymentsThankYou = () => import('./views/Payments/ThankYou.vue');
const PaymentsUserThankYou = () => import('./views/Payments/UserThankYou.vue');

const SettingsIndex = () => import('./views/Settings/Index.vue');
const BusinessSettings = () => import('@/js/modules/business/Settings.vue');
const FallbackPackageSettings = () => import('@/js/modules/fallback_package/Settings.vue');
const SiteSettings = () => import('@/js/views/Settings/Site.vue');
const BillplzSettings = () => import('@/js/modules/billplz/Settings.vue');
const ToyyibpaySettings = () => import('@/js/modules/toyyibpay/Settings.vue');
const PaypalSettings = () => import('@/js/modules/paypal/Settings.vue');
const ChipSettings = () => import('@/js/modules/chip/Settings.vue');
const AffiliateSettings = () => import('@/js/modules/affiliate/Settings.vue');

const CustomerCategoriesIndex = () => import('./views/CustomerCategories/Index.vue');
const CustomerCategoriesShow = () => import('./views/CustomerCategories/Show.vue');
const CustomerCategoriesEdit = () => import('./views/CustomerCategories/Edit.vue');
const CustomerCategoriesCreate = () => import('./views/CustomerCategories/Create.vue');

const CustomersIndex = () => import('./views/Customers/Index.vue');
const CustomersShow = () => import('./views/Customers/Show.vue');
const CustomersEdit = () => import('./views/Customers/Edit.vue');
const CustomersCreate = () => import('./views/Customers/Create.vue');

const CustomerInvoicesIndex = () => import('./views/CustomerInvoices/Index.vue');
const CustomerInvoicesShow = () => import('./views/CustomerInvoices/Show.vue');
// const CustomerInvoicesShowAsUser () => import= ('./views/CustomerInvoices/ShowAsUser.vue' siapa guna ni?)
// const CustomerInvoicesShowAsCustomer = () => import('./views/CustomerInvoices/ShowAsCustomer.vue')
const CustomerInvoicesEdit = () => import('./views/CustomerInvoices/Edit.vue');
const CustomerInvoicesCreate = () => import('./views/CustomerInvoices/Create.vue');
const CustomerInvoicesPay = () => import('./views/CustomerInvoices/Pay.vue');

const CustomerSubcustomersEdit = () => import('./views/CustomerSubcustomers/Edit.vue');
const CustomerSubcustomersCreate = () => import('./views/CustomerSubcustomers/Create.vue');

const ProductsIndex = () => import('./views/Products/Index.vue');
const ProductsShow = () => import('./views/Products/Show.vue');
const ProductsForm = () => import('./views/Products/Form.vue');

const GalleryIndex = () => import('./views/Gallery/Index.vue');

const SubproductsForm = () => import('./views/Products/Subproduct/Form.vue');

const SubscribePackage = () => import('./views/Subscribe/Index.vue');
const SubscribePackageManualPayment = () => import('./views/Subscribe/UserSubscribeManualPayment.vue');
const SubscribeProduct = () => import('./views/Products/CustomerProductSubscribe.vue');
const SubscribeProductManualPayment = () => import('./views/Products/CustomerSubscribeManualPayment.vue');

const CustomNotificationsIndex = () => import('./views/CustomNotifications/Index.vue');
const CustomNotificationsEdit = () => import('./views/CustomNotifications/Edit.vue');

const AddOnsIndex = () => import('./views/AddOns/Index.vue');
const AddOnsEdit = () => import('./views/AddOns/Edit.vue');
const AddOnsBuy = () => import('./views/AddOns/Buy.vue');
const AddOnsManualPayment = () => import('./views/AddOns/ManualPayment.vue');

const NotFound = () => import('./views/app/404.vue');

const SubcustomersIndex = () => import('./views/Subcustomers/Index.vue');
const SubcustomersShow = () => import('./views/Subcustomers/Show.vue');
const SubcustomersEdit = () => import('./views/Subcustomers/Edit.vue');
const SubcustomersCreate = () => import('./views/Subcustomers/Create.vue');

import store from './store';
import NProgress from 'nprogress';

import modules from './modules/index';

import Landing from './views/Landing.vue';

Vue.use(VueRouter);

let host = window.location.host;

const has_tenant_subdomain = host.split('.').length > 2;

const routes = [
  {
    path: '/',
    component: Homepage, //webpackChunkName app
    // redirect: { name: 'landing' },
    redirect: { name: 'dashboard' },
    children: [
      { path: 'dashboard', component: Dashboard, name: 'dashboard' },
      { path: 'profile', component: Profile, name: 'profile' },

      { path: 'packages', component: PackagesIndex, name: 'packages.index' },
      { path: 'packages/create', component: PackagesCreate, name: 'packages.create' },
      { path: 'packages/:id', component: PackagesShow, name: 'packages.show' },
      { path: 'packages/:id/edit', component: PackagesEdit, name: 'packages.edit' },

      { path: 'managers', component: ManagerIndex, name: 'managers.index' },
      { path: 'managers/create', component: ManagerCreate, name: 'managers.create' },
      { path: 'managers/:id', component: ManagerShow, name: 'managers.show' },
      { path: 'managers/:id/edit', component: ManagerEdit, name: 'managers.edit' },

      { path: 'users', component: UsersIndex, name: 'users.index' },
      { path: 'users/create', component: UsersCreate, name: 'users.create' },
      { path: 'users/:id', component: UsersShow, name: 'users.show' },
      { path: 'users/:id/edit', component: UsersEdit, name: 'users.edit' },

      {
        path: 'user_subscriptions/create',
        component: UserSubscriptionCreate,
        name: 'user_subscriptions.create',
      },
      {
        path: 'user_subscriptions/:id/edit',
        component: UserSubscriptionEdit,
        name: 'user_subscriptions.edit',
      },
      /**
       * Manager  -> User Invoices
       * User     -> Own invoices
       * Customer -> Own invoices
       *
       */
      { path: 'invoices', component: InvoicesIndex, name: 'invoices.index' },
      { path: 'invoices/settings', component: InvoicesSettings, name: 'invoices.settings' },
      { path: 'invoices/create', component: InvoicesCreate, name: 'invoices.create' },
      { path: 'invoices/:id', component: InvoicesShow, name: 'invoices.show' },
      { path: 'invoices/:id/edit', component: InvoicesEdit, name: 'invoices.edit' },
      { path: 'invoices/:id/pay', component: UserInvoicesPay, name: 'invoices.pay' },
      { path: 'customer_invoices/:id/pay/manually', component: ManualInvoicePayment, name: 'invoices.pay.manually' },

      { path: 'payments', component: PaymentsIndex, name: 'payments.index' },
      { path: 'payments/thankyou', component: PaymentsThankYou, name: 'payments.thankyou' },
      { path: 'payments/user_thankyou', component: PaymentsUserThankYou, name: 'payments.user_thankyou' },
      { path: 'payments/create', component: PaymentsCreate, name: 'payments.create' },
      { path: 'payments/:id', component: PaymentsShow, name: 'payments.show' },
      { path: 'payments/:id/edit', component: PaymentsEdit, name: 'payments.edit' },

      {
        path: 'settings',
        component: SettingsIndex,
        name: 'settings',
        children: [
          { path: 'business', component: BusinessSettings, name: 'settings.business' },
          { path: 'fallback_package', component: FallbackPackageSettings, name: 'settings.fallback_package' },
          { path: 'site', component: SiteSettings, name: 'settings.site' },
          { path: 'billplz', component: BillplzSettings, name: 'modules.billplz' },
          { path: 'toyyibpay', component: ToyyibpaySettings, name: 'modules.toyyibpay' },
          { path: 'paypal', component: PaypalSettings, name: 'modules.paypal' },
          { path: 'chip', component: ChipSettings, name: 'modules.chip' },
          { path: 'affiliate', component: AffiliateSettings, name: 'modules.affiliate' },
        ],
      },

      { path: 'categories', component: CustomerCategoriesIndex, name: 'customer_categories.index' },
      { path: 'categories/create', component: CustomerCategoriesCreate, name: 'customer_categories.create' },
      { path: 'categories/:id', component: CustomerCategoriesShow, name: 'customer_categories.show' },
      { path: 'categories/:id/edit', component: CustomerCategoriesEdit, name: 'customer_categories.edit' },

      { path: 'customers', component: CustomersIndex, name: 'customers.index' },
      { path: 'customers/create', component: CustomersCreate, name: 'customers.create' },
      { path: 'customers/:id', component: CustomersShow, name: 'customers.show' },
      { path: 'customers/:id/edit', component: CustomersEdit, name: 'customers.edit' },

      /**
       * User -> view customer invoices
       */
      { path: 'customer_invoices', component: CustomerInvoicesIndex, name: 'customer_invoices.index' },
      { path: 'customer_invoices/create', component: CustomerInvoicesCreate, name: 'customer_invoices.create' },
      { path: 'customer_invoices/:id', component: CustomerInvoicesShow, name: 'customer_invoices.show' },
      { path: 'customer_invoices/:id/edit', component: CustomerInvoicesEdit, name: 'customer_invoices.edit' },
      { path: 'customer_invoices/:id/pay', component: CustomerInvoicesPay, name: 'customer_invoices.pay' },

      /**
       * User -> view customer subcustomers
       */
      {
        path: 'customer_subcustomers/create',
        component: CustomerSubcustomersCreate,
        name: 'customer_subcustomers.create',
      },
      {
        path: 'customer_subcustomers/:id/edit',
        component: CustomerSubcustomersEdit,
        name: 'customer_subcustomers.edit',
      },

      {
        path: 'customer_subscriptions',
        component: CustomerSubscriptionIndex,
        name: 'customer_subscriptions.index',
      },
      {
        path: 'customer_subscriptions/create',
        component: CustomerSubscriptionCreate,
        name: 'customer_subscriptions.create',
      },
      {
        path: 'customer_subscriptions/:id/edit',
        component: CustomerSubscriptionEdit,
        name: 'customer_subscriptions.edit',
      },

      { path: 'products', component: ProductsIndex, name: 'products.index' },
      { path: 'products/create', component: ProductsForm, name: 'products.create' },
      { path: 'products/:id', component: ProductsShow, name: 'products.show' },
      { path: 'products/:id/:type', component: ProductsForm, name: 'products.form' },

      { path: 'products/:id/subproduct/create', component: SubproductsForm, name: 'subproducts.create' },
      { path: 'products/:id/subproduct/:subproduct_id/:type', component: SubproductsForm, name: 'subproducts.form' },

      { path: 'gallery', component: GalleryIndex, name: 'gallery.index' },

      {
        path: 'subscribe_package/:subpackage_id/subpackage_option/:package_option',
        component: SubscribePackage,
        name: 'subscribe_package',
      },
      {
        path: 'subscribe_package/:subpackage_id/manual_payment',
        component: SubscribePackageManualPayment,
        name: 'subscribe_package_manual_payment',
      },
      {
        path: 'subscribe_product/:product_id/subproduct/:subproduct_id',
        component: SubscribeProduct,
        name: 'subscribe_product',
      },
      {
        path: 'subscribe_product/:product_id/subproduct/:subproduct_id/manual_payment',
        component: SubscribeProductManualPayment,
        name: 'subscribe_manual_payment',
      },

      { path: 'custom_notifications', component: CustomNotificationsIndex, name: 'custom_notifications.index' },
      {
        path: 'custom_notifications/:id/edit/:notification_type',
        component: CustomNotificationsEdit,
        name: 'custom_notifications.edit',
      },

      { path: 'add_ons', component: AddOnsIndex, name: 'add_ons.index' },
      { path: 'add_ons/:id/edit', component: AddOnsEdit, name: 'add_ons.edit' },
      { path: 'add_ons/buy', component: AddOnsBuy, name: 'add_ons.buy' },
      { path: 'add_ons/manual_payment/:id', component: AddOnsManualPayment, name: 'add_ons.manual_payment' },

      //---------------- affiliate module - manager side

      // { path: 'affiliates', component: ManagerAffiliateAgentsIndex, name: 'manager.affiliates', },

      { path: 'affiliate_agents', component: ManagerAffiliateAgentsIndex, name: 'manager.affiliate_agents.index' },
      {
        path: 'affiliate_agents/:affiliate_agent_id',
        component: ManagerAffiliateAgentsShow,
        name: 'manager.affiliate_agents.show',
      },
      {
        path: 'affiliate_agents/:affiliate_agent_id/edit',
        component: ManagerAffiliateAgentsEdit,
        name: 'manager.affiliate_agents.edit',
      },

      // TODO:
      // { path: 'affiliate_agents/:affiliate_agent_id/commissions', component: ManagerAffiliateAgentsCommissions, name: 'manager.affiliate_agents.commissions', },
      // { path: 'affiliate_agents/:affiliate_agent_id/withdrawals', component: ManagerAffiliateAgentsWithdrawals, name: 'manager.affiliate_agents.withdrawals', },

      {
        path: 'affiliate_commissions',
        component: ManagerAffiliateCommissionsIndex,
        name: 'manager.affiliate_commissions.index',
      },
      {
        path: 'affiliate_commissions/:commission_id',
        component: ManagerAffiliateCommissionsEdit,
        name: 'manager.affiliate_commissions.show',
      },

      {
        path: 'affiliate_payments',
        component: ManagerAffiliatePaymentsIndex,
        name: 'manager.affiliate_payments.index',
      },
      {
        path: 'affiliate_payments/:payment_id',
        component: ManagerAffiliatePaymentsEdit,
        name: 'manager.affiliate_payments.show',
      },

      // affiliate module - affiliate agent side
      { path: 'referrals', component: AffiliateReferralsIndex, name: 'affiliate.referrals.index' },
      { path: 'commissions', component: AffiliateCommissionsIndex, name: 'affiliate.commissions.index' },
      { path: 'withdrawals', component: AffiliateWithdrawalsIndex, name: 'affiliate.withdrawals.index' },

      // subcustomer module
      { path: 'subcustomers', component: SubcustomersIndex, name: 'subcustomers.index' },
      { path: 'subcustomers/create', component: SubcustomersCreate, name: 'subcustomers.create' },
      { path: 'subcustomers/:id', component: SubcustomersShow, name: 'subcustomers.show' },
      { path: 'subcustomers/:id/edit', component: SubcustomersEdit, name: 'subcustomers.edit' },
    ],
  },
  // {
  //   path: '/landing',
  //   component: Landing,
  //   name: 'landing',
  // },
  {
    path: '/logout',
    component: Logout,
    name: 'logout',
  },

  ...(has_tenant_subdomain
    ? [
        {
          path: '/user/login',
          component: UserLogin,
          name: 'user.login',
        },
        {
          path: '/user/forgot',
          component: UserForgotPassword,
          name: 'user.forgot',
        },
        {
          path: '/user/password/reset/:token',
          component: UserPasswordReset,
          name: 'user.password.reset',
        },

        {
          path: '/customer/register',
          component: CustomerRegister,
          name: 'customer.register',
        },
        {
          path: '/customer/login',
          component: CustomerLogin,
          name: 'customer.login',
        },
        {
          path: '/customer/forgot',
          component: CustomerForgotPassword,
          name: 'customer.forgot',
        },
        {
          path: '/customer/password/reset/:token',
          component: CustomerPasswordReset,
          name: 'customer.password.reset',
        },
      ]
    : [
        {
          path: '/manager/login',
          component: ManagerLogin,
          name: 'manager.login',
        },
        {
          path: '/manager/forgot',
          component: ManagerForgotPassword,
          name: 'manager.forgot',
        },
        {
          path: '/manager/password/reset/:token',
          component: ManagerPasswordReset,
          name: 'manager.password.reset',
        },
        {
          path: '/affiliate/login',
          component: AffiliateLogin,
          name: 'affiliate.login',
        },
        {
          path: '/affiliate/register',
          component: AffiliateRegister,
          name: 'affiliate.register',
        },
        {
          path: '/affiliate/forgot',
          component: AffiliateForgotPassword,
          name: 'affiliate.forgot',
        },
        {
          path: '/affiliate/password/reset/:token',
          component: AffiliatePasswordReset,
          name: 'affiliate.password.reset',
        },
        {
          path: '/user/register',
          component: UserRegister,
          name: 'user.register',
        },
      ]),

  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: 'open',
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // If this isn't an initial page load.
  if (to.path) {
    // Start the route progress bar.
    NProgress.start();
    NProgress.set(0.1);
  }

  next();
});

router.afterEach(() => {
  // Remove initial loading
  const gullPreLoading = document.querySelector('#loading_wrap');

  if (gullPreLoading) gullPreLoading.style.display = 'none';

  // Complete the animation of the route progress bar.
  setTimeout(() => NProgress.done(), 500);

  // is mobile
  let isMobile = window.innerWidth <= 1200;

  if (isMobile) {
    store.dispatch('changeSidebarProperties');

    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch('changeSecondarySidebarProperties');
    }

    // console.log(store.getters.getCompactSideBarToggleProperties)
    // if (store.getters.getCompactSideBarToggleProperties.isSideNavOpen) {
    //   store.dispatch("changeCompactSidebarProperties")
    // }
  } else {
    if (store.getters.getSideBarToggleProperties.isSecondarySideNavOpen) {
      store.dispatch('changeSecondarySidebarProperties');
    }
    // store.state.sidebarToggleProperties.isSecondarySideNavOpen = false
  }
});

export default router;
