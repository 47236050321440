import manual_payment from './manual_payment/index';
import billplz from './billplz/index';
import toyyibpay from './toyyibpay/index';
import paypal from './paypal/index';
import chip from './chip/index';
import affiliate from './affiliate/index';
import fallback_package from './fallback_package/index';
import business from './business/index';

import SiteMenuItem from '@/js/views/Settings/SiteMenuItem.vue';

const routes = [
  ...affiliate.routes,
  ...manual_payment.routes,
  ...billplz.routes,
  ...toyyibpay.routes,
  ...paypal.routes,
  ...chip.routes,
  ...fallback_package.routes,
  ...business.routes,
];

const menu_items = [
  business.menu_item,
  fallback_package.menu_item,
  affiliate.menu_item,
  manual_payment.menu_item,
  billplz.menu_item,
  toyyibpay.menu_item,
  paypal.menu_item,
  chip.menu_item,
  SiteMenuItem,
];

export default {
  routes,
  menu_items,
};
