export default {
  global: {
    field: {
      action: 'Action',
    },

    action: {
      edit: 'Edit',
    },

    status: {},
  },

  sidebar: {
    dashboard: 'Dashboard',
    packages: 'Packages',
    managers: 'Managers',
    users: 'Users',
    invoices: 'Invoices',
    payments: 'Payments',
    settings: 'Settings',
  },

  dashboard: {
    page_title: 'Dashboard',
    totalUsers: 'Users',
    totalSubscriptions: 'Subscriptions',
    todays_sales: `Today's Sales`,
    unpaid_invoices: 'Unpaid Invoices',
  },

  manager: {
    // sidebar_item: 'Manager',
    page_title: {
      index: 'Managers',
      create: 'Create New Manager',
      edit: 'Edit Manager',
      show: 'View Manager',
    },
    action: {
      add: 'Add',
      edit: 'Edit',
      save: 'Save',
      update: 'Update',
      delete: 'Delete',
    },
    fields: {
      name: 'Name',
      form_name: 'Manager Name', //remove
      name_form: 'Manager Name',
      email: 'Email',
      form_email: 'Manager Email', //remove
      email_form: 'Manager Email',
      action: 'Action',
      email_verified: 'Email Verified',
      password: 'Password',
      password_form: 'Manager Password',
      password_confirmation: 'Password Confirmation',
      password_confirmation_form: 'Manager Password Confirmation',
      registration_date: 'Registration Date',
    },
    status: {
      email: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
      phone: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
    },
  },

  user: {
    // sidebar_item: 'User',
    page_title: {
      index: 'Users',
      edit: 'Edit User',
      create: 'Create New User',
      show: 'View User',
    },
    action: {
      add: 'Add',
      edit: 'Edit',
      save: 'Save',
      update: 'Update',
      delete: 'Delete',
    },
    fields: {
      name: 'Name',
      name_form: 'User Name',
      email: 'Email',
      email_form: 'User Email',
      password: 'Password',
      password_form: 'User Password',
      subdomain: 'Subdomain',
      subdomain_form: 'User Subdomain',
      password_confirmation: 'Password Confirmation',
      password_confirmation_form: 'User Password Confirmation',
      registration_date: 'Registration Date',

      email_verified: 'User Verified',
      action: 'Action',
    },
    status: {
      email: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
      phone: {
        verified: 'Verified',
        unverified: 'Unverified',
      },
    },
  },

  user_subscriptions: {
    action: {},

    labels: {
      package: 'Package',
      actions: 'Actions',
    },

    fields: {
      id: 'ID',
      start_date: 'Start Date',
      end_date: 'End Date',
      renew_date: 'Renew Date',
    },

    status: {},
  },

  invoice: {},

  my_invoice: {},

  customer_invoice: {},
};
