import BootstrapVue from 'bootstrap-vue';
// import vuePerfectScrollbar from "vue-perfect-scrollbar"
// import VueTour from "vue-tour"
// import SmartTable from "vuejs-smart-table"
import VueTagsInput from '@johmun/vue-tags-input';
import VueTheMask from 'vue-the-mask';
import Vuelidate from 'vuelidate';
// import VueFormWizard from "vue-form-wizard"
import VueLazyload from 'vue-lazyload';
// import VCalendar from "v-calendar"
import VueGoodTablePlugin from 'vue-good-table';
// import VueSlider from "vue-slider-component"
import Meta from 'vue-meta';
// import FlagIcon from "vue-flag-icon"

// import "../../sass/themes/lite-purple.scss"
import './echarts';
import './apexChart.js';
import './sweetalert2.js';
import './fontawesome';

import vSelect from 'vue-select';
import 'vue-select/src/scss/vue-select.scss';

// import i18n from "@/js/lang/lang.js"

// locale.use(lang)

export default {
  install(Vue) {
    // Vue.use(i18n)

    Vue.component('v-select', vSelect);

    Vue.use(BootstrapVue);

    Vue.component('large-sidebar', () => import('../containers/layouts/largeSidebar'));

    Vue.component('vue-perfect-scrollbar', () => import('vue-perfect-scrollbar'));

    // Vue.component(VueCropper)

    Vue.use(Meta, {
      keyName: 'metaInfo',
      attribute: 'data-vue-meta',
      ssrAttribute: 'data-vue-meta-server-rendered',
      tagIDKeyName: 'vmid',
      refreshOnceOnNavigation: true,
    });

    // Vue.use(FlagIcon)

    // vueslider
    // Vue.component("VueSlider", () => import("vue-slider-component"))

    //vuelidate
    Vue.use(Vuelidate);

    // maskForm
    Vue.use(VueTheMask);

    //form wizard globally
    // Vue.use(VueFormWizard)

    // tags input plugin
    Vue.use(VueTagsInput);

    // smart table plugin
    // Vue.use(SmartTable)

    // vue-good-table
    // import the styles
    Vue.use(VueGoodTablePlugin);

    // import VueCropper from "./plugins/imageCropper"

    // Use v-calendar & v-date-picker components
    // Vue.use(VCalendar, {
    //   componentPrefix: "v" // Use <vc-calendar /> instead of <v-calendar />
    //   // ...other defaults
    // })

    // Vue.use(VueTour)

    Vue.use(VueLazyload, {
      observer: true,
      // optional
      observerOptions: {
        rootMargin: '0px',
        threshold: 0.1,
      },
    });
  },
};
