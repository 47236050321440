const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchAll(ctx) {
    try {
      let {
        data: { data: subcustomers },
      } = await axios.get('/customer_api/subcustomers');
      return subcustomers;
    } catch (error) {
      throw error.response.data;
    }
  },

  async add(ctx, data) {
    try {
      let {
        data: { success, message },
      } = await axios.post('/customer_api/subcustomers', data);
      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async fetchByID(ctx, id) {
    try {
      let {
        data: { data: subcustomer },
      } = await axios.get(`/customer_api/subcustomers/${id}`);
      return subcustomer;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateByID(ctx, { id, data }) {
    try {
      let {
        data: { success, message },
      } = await axios.put(`/customer_api/subcustomers/${id}`, data);
      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async deleteByID(ctx, id) {
    try {
      let {
        data: { success, message },
      } = await axios.post(`/customer_api/subcustomers/${id}`, { _method: 'delete', subcustomer: id });
      return { success, message };
    } catch (error) {
      throw error.response.data;
    }
  },

  async fetchExtraField() {
    try {
      let {
        data: { data: extra_field },
      } = await axios.get(`/customer_api/subcustomers/extra_fields`);
      return extra_field;
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced,
};
