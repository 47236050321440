<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'App',

  beforeCreate() {
    const Manager = window.Laravel.hasOwnProperty('Manager') ? window.Laravel.Manager : null;
    const Affiliate = window.Laravel.hasOwnProperty('Affiliate') ? window.Laravel.Affiliate : null;
    const User = window.Laravel.hasOwnProperty('User') ? window.Laravel.User : null;
    const Customer = window.Laravel.hasOwnProperty('Customer') ? window.Laravel.Customer : null;
    const locale = window.Laravel.hasOwnProperty('config') ? window.Laravel.config.locale : 'ms';

    this.$store.dispatch('auth/setManager', Manager);
    this.$store.dispatch('auth/setAffiliate', Affiliate);
    this.$store.dispatch('auth/setUser', User);
    this.$store.dispatch('auth/setCustomer', Customer);
    this.$store.dispatch('setLocale', locale);
  },

  computed: {
    ...mapGetters(['getThemeMode']),

    themeName() {
      return this.getThemeMode.dark ? 'dark-theme' : ' ';
    },

    rtl() {
      return this.getThemeMode.rtl ? 'rtl' : ' ';
    },
  },

  metaInfo() {
    return {
      title: 'Home',
      titleTemplate: '%s | fees.my',
      bodyAttrs: {
        class: [this.themeName, 'text-left'],
      },
      htmlAttrs: {
        dir: this.rtl,
      },
    };
  },
};
</script>

<style></style>
