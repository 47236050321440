<template>
  <b-tab :title="__('Affiliate')">
    <Affiliate />
  </b-tab>
</template>

<script>
import Affiliate from './Settings';

export default {
  components: {
    Affiliate,
  },
};
</script>
