import manager_http from '@/js/http/manager.js';

const SET_TOTAL_SUBSCRIPTIONS = 'setTotalSubs';
const TOTAL_SUBS_KEY = 'totalSubscriptions';

const SET_TOTAL_USERS = 'setTotalusers';
const TOTAL_USERS_KEY = 'totalUsers';

const SET_TOTAL_SALES = 'setTotalSales';
const TOTAL_SALES_KEY = 'totalSalesToday';

const SET_TOTAL_INVOICES_UNPAID = 'setTotalInvoicesUnpaid';
const TOTAL_INVOICES_UNPAID_KEY = 'totalInvoicesUnpaid';

let axios = manager_http().axios();

const state = {
  totalUsers: 0,
  totalSubscriptions: 0,
  totalSalesToday: 0,
  totalInvoicesUnpaid: 0,
};

const getters = {
  totalUsers: (state) => state.totalUsers,

  totalSubscriptions: (state) => state.totalSubscriptions,

  totalSalesToday: (state) => state.totalSalesToday,

  totalInvoicesUnpaid: (state) => state.totalInvoicesUnpaid,
};

const mutations = {
  [SET_TOTAL_USERS](state, { totalUsers }) {
    state.totalUsers = totalUsers;
  },

  [SET_TOTAL_SUBSCRIPTIONS](state, { totalSubscriptions }) {
    state.totalSubscriptions = totalSubscriptions;
  },

  [SET_TOTAL_SALES](state, { totalSalesToday }) {
    state.totalSalesToday = totalSalesToday;
  },

  [SET_TOTAL_INVOICES_UNPAID](state, { totalInvoicesUnpaid }) {
    state.totalInvoicesUnpaid = totalInvoicesUnpaid;
  },
};

const actions = {
  fetchStatistics({ commit }) {
    try {
      axios.get('dashboard').then(({ data }) => {
        commit(SET_TOTAL_SUBSCRIPTIONS, data[TOTAL_SUBS_KEY]);
        commit(SET_TOTAL_SALES, data[TOTAL_SALES_KEY]);
        commit(SET_TOTAL_USERS, data[TOTAL_USERS_KEY]);
        commit(SET_TOTAL_INVOICES_UNPAID, data[TOTAL_INVOICES_UNPAID_KEY]);
      });
    } catch (error) {
      throw error.response.data;
    }
  },
};

const namespaced = true;

export default { state, getters, mutations, actions, namespaced };
