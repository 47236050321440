import base_http from './base';
import Router from '@/js/router.js';

const http = function (args) {
  const instance = base_http(args);

  return Object.assign({}, instance, {
    getBaseUrl: function () {
      let parent_base_url = instance.getBaseUrl();

      return `${parent_base_url}/api`;
    },

    errorHandler: (error) => {
      if (error.response !== undefined) {
        if (error.response.status == 404) {
          // Router.replace('/404')
        } else if (error.response.status == 401) {
          Router.replace({ name: 'manager.login' });
        }
      }

      return Promise.reject(error);
    },
  });
};

export default http;
