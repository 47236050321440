<template>
  <div>
    <breadcrumb :page="__('Chip Settings')" />

    <b-row>
      <b-col sm="12">
        <b-form @submit.prevent="saveSettings" v-if="!fetching">
          <b-form-group :label="__('Activate')">
            <b-form-checkbox v-model="setting.enabled"></b-form-checkbox>
          </b-form-group>

          <b-form-group :label="__('Mode')">
            <b-select
              :options="[
                { text: 'Test', value: 'test' },
                { text: 'Live', value: 'live' },
              ]"
              v-model="setting.settings.mode"
              :placeholder="__('Select Mode...')"
            >
            </b-select>
          </b-form-group>

          <b-form-group>
            <h4>{{ __('Test Mode Settings') }}</h4>
            <b-form-group :label="__('Brand ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.test.brandId"
                :placeholder="__('Enter Brand ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('API Key')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.test.apiKey"
                :placeholder="__('Enter API key...')"
              ></b-form-input>
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <h4>{{ __('Live Mode Settings') }}</h4>
            <b-form-group :label="__('Brand ID')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.live.brandId"
                :placeholder="__('Enter Brand ID...')"
              ></b-form-input>
            </b-form-group>

            <b-form-group :label="__('API Key')">
              <b-form-input
                class="form-control"
                v-model="setting.settings.live.apiKey"
                :placeholder="__('Enter API key...')"
              ></b-form-input>
            </b-form-group>
          </b-form-group>

          <b-form-group>
            <b-button type="submit" variant="primary" :disabled="processing">
              <b-spinner variant="light" small v-if="processing"></b-spinner>
              <span>{{ __('Save') }}</span>
            </b-button>
          </b-form-group>
        </b-form>

        <b-alert :variant="success ? 'success' : 'danger'" v-if="message" show>
          {{ message }}
        </b-alert>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      fetching: false,
      success: false,
      message: '',
      setting: {
        settings: {
          live: {
            apiKey: '',
            brandId: '',
          },
          mode: '',
          test: {
            apiKey: '',
            brandId: '',
          },
        },
      },
      processing: false,
    };
  },

  beforeMount() {
    this.fetchSetting();
  },

  methods: {
    fetchSetting() {
      this.fetching = true;
      axios
        .get('/api/payment_gateways/showByName/chip')
        .then(({ data: { data: setting } }) => {
          this.setting = { ...setting, enabled: setting.enabled == 1 };
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    },

    saveSettings() {
      this.processing = true;

      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          let postData = {
            _method: 'put',
            enabled: this.setting.enabled == 1,
            ...this.setting,
          };

          axios
            .post(`/api/payment_gateways/${this.setting.id}`, postData)
            .then(({ data: { message, success } }) => {
              this.message = message;
              this.success = success;
            })
            .catch((error) => {
              console.log(error);
              alert(error);
            })
            .finally(() => {});
        })
        .catch((error) => {
          console.log(error);
          alert(error);
        })
        .finally(() => {
          this.processing = false;
        });
    },
  },
};
</script>

<style></style>
