<template>
  <b-tab :title="__('Chip')">
    <Chip />
  </b-tab>
</template>

<script>
import Chip from './Settings';

export default {
  components: {
    Chip,
  },
};
</script>
