import Settings from './Settings.vue';
import MenuItem from './MenuItem.vue';

const routes = [
  {
    name: 'modules.affiliate',
    component: Settings,
    path: '/modules/affiliate',
  },
];

export default {
  menu_item: MenuItem,
  settings_component: Settings,
  routes,
};
