<template>
  <b-tab :title="__('PayPal')">
    <PayPal />
  </b-tab>
</template>

<script>
import PayPal from './Settings';

export default {
  components: {
    PayPal,
  },
};
</script>
